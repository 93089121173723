body {
  direction: rtl;
}

 
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style-type: none;
   direction: rtl;
}

.pagination .page-item {
  color: #616871;
  opacity: 0.5;
  margin-inline: 12px;
}

.pagination .active_page,
.pagination .navigate {
  opacity: 1;
}

.navigate{
  transform: rotate(180deg);
}

 
.activeClassName a {
  background-color: #eaf4ff;
  color: #1ba3ef;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
}